<template>
  <div>
    <div v-if="this.data.length > 0" class="container okoscsomag">
      <h1 class="col-12 text-center my-5">OKOSCSOMAGJAINK</h1>
      <div class="container px-0">
        <div v-for="(item, index) in (this.data)" :key="index">
          <ProductList :data="item" :index="index"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import ProductList from "@/components/partials/ProductList";

export default {
  name: "module14",
  components: {ProductList},
  props: {
    data: {data: [Array]},
  }
}
</script>

<style scoped>

</style>